<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height>
		<CatalogServiceDetail
			v-if="service_id_selected != null"
			:back-closure="hideDetailsAboutService"
			:services-selected="[]"
			:service-id="service_id_selected"
			:filters="filters"
			:catalog-offer-ids="catalogOfferIds"
			:catalog-service-ids="catalogServiceIds"
		></CatalogServiceDetail>
		<w-flex v-else scroll-y>
			<w-layout v-if="offer !== null" column fill-height>
				<v-toolbar color="primary" dark flat height="78px">
					<w-layout align-center row>
						<w-flex grow>
							<w-layout column>
								<v-toolbar-title v-text="name" />
							</w-layout>
						</w-flex>
						<w-flex shrink>
							<w-btn color flat icon="close" mini @click="close()">{{ $t('actions.close') }}</w-btn>
						</w-flex>
					</w-layout>
				</v-toolbar>
				<w-flex class="px-4 py-4">
					<w-layout column fill-height>
						<w-flex scroll-y>
							<p v-if="categories.length >= 1" class="mb-3">
								<span class="font-weight-bold">{{ $t('service-requests.w-navigation.offer.categories') }} :</span> <br />
								<v-chip v-for="category in categories" :key="category.id" text-color="#FFFFFF" class="ml-0" :color="category.color">{{ category.name }}</v-chip>
							</p>
							<div class="ql-bubble mb-4">
								<div class="ql-editor px-0 py-0" v-html="description"></div>
							</div>
							<p v-if="duration !== null" class="">
								<span class="font-weight-bold">{{ $t('service-requests.w-navigation.offer.durations') }} :</span>
								{{ $t('service-requests.durations.' + duration) }}
							</p>
							<p v-if="price !== null" class="">
								<span class="font-weight-bold">{{ $t('service-requests.w-navigation.offer.price') }} :</span>
								{{ price }}
							</p>
							<ServicesList v-if="services.length > 0" :value="services" :select-target="displayDetailsAboutService" />
						</w-flex>
						<w-flex style="flex-grow: 0">
							<w-btn v-if="!isSelected" class="ml-0" icon="add" small @click.stop="addOffer">
								{{ $t('actions.select') }}
							</w-btn>
							<w-btn v-if="isSelected" class="ml-0" icon="remove" :disabled="catalogOfferIds.includes(offerId)" small @click.stop="removeOffer">
								{{ $t('actions.unselect') }}
							</w-btn>
						</w-flex>
					</w-layout>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>
<script>
import 'quill/dist/quill.bubble.css'
import ServiceRequestsModuleGuard from '@/mixins/ModulesGuards/ServiceRequests/ServiceRequestsModuleGuard'
export default {
	name: 'CatalogOfferDetail',
	components: {
		ServicesList: () => ({
			component: import('@/components/ServiceRequests/CatalogOfferServicesList')
		}),
		CatalogServiceDetail: () => ({
			component: import('@/components/ServiceRequests/CatalogServiceDetail')
		})
	},
	mixins: [ServiceRequestsModuleGuard],
	props: {
		offersSelected: {
			required: true,
			type: Array
		},
		offerId: {
			required: false,
			type: Number,
			default: undefined
		},
		filters: {
			default: () => ({
				categories: []
			}),
			required: true,
			type: Object
		},
		catalogOfferIds: {
			required: true,
			type: Array
		},
		catalogServiceIds: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			offer: null,
			service_id_selected: null
		}
	},
	computed: {
		services: function () {
			if (typeof this.offer?.catalog_services === 'undefined') {
				return []
			}
			return this.offer.catalog_services
		},
		categories: function () {
			if (typeof this.offer?.catalog_services === 'undefined') {
				return []
			}
			let a = {}
			this.offer.catalog_services
				.map(service => service.category)
				.forEach(category => {
					a[category.id] = category
				})
			return Object.values(a)
		},
		name: function () {
			return typeof this.offer?.name === 'undefined' ? 'Offre inconnue' : this.offer.name
		},
		duration: function () {
			return typeof this.offer?.duration?.name === 'undefined' ? null : this.offer.duration.name
		},
		description: function () {
			return typeof this.offer?.description === 'undefined' ? '' : this.offer.description
		},
		price: function () {
			return typeof this.offer?.price === 'undefined'
				? null
				: new Intl.NumberFormat('fr-FR', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 2
				  })
						.format(this.offer.price)
						.toString()
		},
		isSelected () {
			return this.offersSelected.includes(this.offer.id)
		}
	},
	watch: {
		offerId: {
			handler: function (newVal, oldVal) {
				if (typeof newVal === 'undefined') {
					this.close()
					return
				}
				if (newVal === oldVal) {
					return
				}
				this.findOffer()
			},
			immediate: true
		}
	},
	mounted () {},
	methods: {
		close: function () {
			this.appService.goTo(
				(() => {
					let r = { name: 'service-requests-catalog', query: {} }
					if (this.filters.categories.length >= 1) {
						r.query['categories'] = this.filters.categories.map(c => c.toString(16)).join(',')
					}
					if (this.catalogServiceIds.length >= 1) {
						r.query['catalog-service'] = this.catalogServiceIds.map(c => c.toString(16)).join(',')
					}
					if (this.catalogOfferIds.length >= 1) {
						r.query['catalog-offer'] = this.catalogOfferIds.map(c => c.toString(16)).join(',')
					}
					return r
				})()
			)
		},
		findOffer: async function () {
			try {
				this.$emit('loading')
				this.offer = await this.service.findCatalogOffer(this.accountingFirmId, this.offerId).promise
				this.$emit('loaded')
			} catch (err) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('non_existing_resource'))
				this.appService.goTo('service-requests-catalog')
			}
		},
		addOffer: function () {
			this.$emit('loading')
			this.eventBus.emit(this.events.OFFER_SELECT, this.offer.id)
			setTimeout(() => {
				this.$emit('loaded')
				this.close()
			}, 500)
		},
		removeOffer: function () {
			this.$emit('loading')
			this.eventBus.emit(this.events.OFFER_UNSELECT, this.offer.id)
			setTimeout(() => {
				this.$emit('loaded')
				this.close()
			}, 500)
		},
		displayDetailsAboutService: function (service) {
			this.service_id_selected = service.id
		},
		hideDetailsAboutService: function () {
			this.service_id_selected = null
		}
	}
}
</script>
